body,
html,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f4f5fa;
  
}

  
