.box-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  overflow: hidden;
  position: relative;
  filter: grayscale(100%);
}

.img-zoom {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  animation: zoomIn 5s ease-in-out forwards;
  transition: opacity 5s ease-in-out;
}

@media (min-width: 768px) {
  .img-zoom {
    width: 300px;
    height: 300px;
  }

  @keyframes zoomIn {
    0% {
      transform: scale(1);
      border-radius: 50%;
      transform-origin: center;
      opacity: 1;
    }
    100% {
      transform: scale(5);
      border-radius: 50%;
      opacity: 0.7;
    }
  }
}

@media (max-width: 768px) {
  .img-zoom {
    width: 150px;
    height: 150px;
  }

  @keyframes zoomIn {
    0% {
      transform: scale(1);
      border-radius: 50%;
      transform-origin: center;
      opacity: 1;
    }
    100% {
      transform: scale(4);
      border-radius: 50%;
      opacity: 0.7;
    }
  }
}
